import React from 'react';
import { Row, Container } from 'react-bootstrap';
import ContactForm from '../contact-form/contact-form';
import './contact.scss';

/* eslint-disable-next-line */
export interface ContactProps {}

export function Contact(props: ContactProps) {
  return (
    <div className="contactSecContainer" id="getStarted">
      <Container data-sal="fade-in" data-sal-duration="1000" data-sal-easing="ease">

        <Row>
          <div className="col-md-12">
            <div className="contactSecContentHoler">
              <h4>We’d love to hear from you!</h4>
              <ContactForm></ContactForm>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
