import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import './footer.scss';

import axios from 'axios';
import Promise from 'promise';
import { validateForm } from '../../assets/js/custom-validation';
import emailLogo from '../../assets/images/logo-footer-orange-email.jpg';
import footerLogo from '../../assets/images/logo-footer-orange.png';

//export interface ContactFormProps {}
const apiUrl = process.env.apiUrl;
const marketingWebUrl = process.env.marketingWebUrl;

/* eslint-disable-next-line */
export interface FooterProps {}
class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }
  handleFormSubmit(event) {
    event.preventDefault();

    //Reset State
    this.setState({ error: null, mailSent: false });

    const formEl = this.formEl;

    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>Contact Us</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #ff8e49; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Admin,<br><br>You are receiving this because someone has subscribed from the website Stay in Loop form. <a href="${marketingWebUrl}">${marketingWebUrl}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>Email*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                      </tbody>
                  </table>
                  <p>SYCCURE Team, <br><br><img src="${emailLogo}" width="auto" alt="SYCCURE"/></p>
              </div>
          </div>
      </body>
      </html>`;

    //If form validate
    if (validateForm(formEl)) {
      const html = templateData.replace('[Email]', this.state.email);

      const data = {
        emailSubject: 'Newsletter Lead from Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${apiUrl}/send-email`,
          headers: {
            // 'content-type': 'application/json',
            Authorization: 'Syccure',
          },
          data: data,
        })
          .then((result) => {
            if (result.data.message) {
              this.setState({
                email: '',
                mailSent: true,
                isValidated: false,
              });
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => this.setState({ error: error.message }));
      });
    } else {
      this.setState({ isValidated: true });
      return false;
    }
  }
  render() {
    let classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }
    return (
      <footer>
        <Container>
          <div className="footerSec">
            <div>
              <Link to="/">
                <img src={footerLogo} alt="footer logo" />
              </Link>
            </div>
            <div className="footerContentHolder">
              <a href="tel:914-919-9301">(914) 919-9301</a>
              <a href="mailto:info@syccure.com">info@syccure.com</a>
            </div>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
