import React from 'react';
import './contact-form.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import Promise from 'promise';
import { validateForm } from '../../assets/js/custom-validation';
import emailLogo from '../../assets/images/logo-orange.png';

//export interface ContactFormProps {}
const apiUrl = process.env.apiUrl;
const marketingWebUrl = process.env.marketingWebUrl;

/* eslint-disable-next-line */

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      mailSent: false,
      error: null,
      isValidated: false,
    };
  }
  handleFormSubmit(event) {
    event.preventDefault();

    //Reset State
    this.setState({ error: null, mailSent: false });

    const formEl = this.formEl;

    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>Contact Us</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #ff8e49; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear Admin,<br><br>You are receiving this because someone has posted a Contact us request from the website. <a href="${marketingWebUrl}">${marketingWebUrl}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                          <tr><td><b>Last Name*</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                          <tr><td><b>Email*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                          <tr><td colspan='2'><b>Message</b></td></tr>
                          <tr><td colspan='2'><span class="vcolor">[Message]</span></td></tr>
                      </tbody>
                  </table>
                  <p>SYCCURE Team, <br><br><img src="${emailLogo}" width="auto" alt="SYCCURE"/></p>
              </div>
          </div>
      </body>
      </html>`;

    //If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.firstName)
        .replace('[LastName]', this.state.lastName)
        .replace('[Email]', this.state.email)
        .replace('[Message]', this.state.message);

      const data = {
        emailSubject: 'Contact us Lead from Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${apiUrl}/send-email`,
          headers: {
            // 'content-type': 'application/json',
            Authorization: 'Syccure',
          },
          data: data,
        })
          .then((result) => {
            if (result.data.message) {
              this.setState({
                firstName: '',
                lastName: '',
                email: '',
                message: '',
                mailSent: true,
                isValidated: false,
              });
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => this.setState({ error: error.message }));
      });
    } else {
      this.setState({ isValidated: true });
      return false;
    }
  }
  render() {
    let classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }
    return (
      <form
        method="post"
        action="#"
        ref={(form) => (this.formEl = form)}
        className={classNames}
        noValidate
      >
        {this.state.mailSent && (
          <div className="alert-success alert text-center mb-5">
            Thank you for contacting us.
          </div>
        )}
        {this.state.error && (
          <div className="alert-danger error alert text-center  mb-5">
            {this.state.error}
          </div>
        )}
        <div className="row contact-form">
          <div className="col-md-6">
            <div className="form-group">
              <input
                placeholder="First Name"
                type="text"
                className="form-control"
                required={true}
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                placeholder="Last Name"
                type="text"
                className="form-control"
                required={true}
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                placeholder="Email"
                type="email"
                className="form-control"
                required={true}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <textarea
                placeholder="Type your message"
                className="form-control"
                required={true}
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              ></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                value="Send"
                type="submit"
                className="btn btn-secondary btn-lg"
                onClick={(e) => this.handleFormSubmit(e)}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}
ContactForm.propTypes = {
  onToggleFormModel: PropTypes.func,
};
export default ContactForm;
