import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import './header.scss';
import logoBlue from '../../assets/images/logo-blue.png';
import logoOrange from '../../assets/images/logo-orange.png';
import About from '../about/about';

/* eslint-disable-next-line */
export interface HeaderProps {
  headerClass?: string;
}

class Header extends Component<HeaderProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.fixbar();
  }

  fixbar = () => {
    const shrinkHeader = 500;
    const header = document.getElementsByClassName('header')[0];
    window.addEventListener('scroll', function (event) {
      const scroll = getCurrentScroll();
      if (scroll >= shrinkHeader) {
        header.classList.add('shrink');
      } else {
        header.classList.remove('shrink');
      }
    });

    function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    }
  };
  render() {
    const { headerClass } = this.props;
    return (
      <header className={headerClass ? headerClass : 'header'}>
        <Navbar fixed="top" bg="dark" expand="lg">
          <Container>
            <Link className="navbar-brand" to="/" activeClassName="active">
              <span className=" shrinkLogo logo-orange">
                <img src={logoOrange} alt="Logo orange" />
              </span>
              <span className="whiteLogo logo-blue">
                <img src={logoBlue} alt="Logo Blue" />
              </span>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="d-md-none sideNavToggler">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              </div>
              <Nav className="mr-auto ml-auto">
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/#about"
                >
                  About Us
                </Link>
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/solution"
                >
                  Solution
                </Link>
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/benefits"
                >
                  Benefits
                </Link>
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/contact"
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default Header;
